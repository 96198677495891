
(function($) {

  var sliderClinics = $('#carousel-clinics');  
  if ( 0 < sliderClinics.length ){

  var options = {
    infinite:false,
    autoplay: false,
    pauseOnFocus : false,
    cssEase: 'linear',
    slidesToShow: 1,
    slideToScroll:1,
    variableWidth:true,
    swipeToSlide: true,
    mobileFirst:true,
    prevArrow: '<button class="carousel-clinic__prev icon-back"></button>',
    nextArrow: '<button class="carousel-clinic__next icon-next"></button>',
    responsive :[ 
      {
      breakpoint: 1320,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }]
  
  };


    sliderClinics.slick(options);

    $(window).on('resize', function() {
      if(!sliderClinics.hasClass('slick-initialized')){
        sliderClinics.slick(options);
    }
    });
  }
})( jQuery );